<template>

    <div>
        <Toast />
        <div class="flex mb-3">
            <h1>Configuraciones</h1>


        </div>
        <div class="">
          <div class="">
            <div class="formgrid grid">
              <div class="field col-2">
            <label for="dolarvalue">Valor del Dolar</label>
                <InputText
                  v-model="this.configuraciones.dolarvalue"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              </div>    
          </div>
        </div>
        <div class="">
          <div class="">
            <div class="formgrid grid">
              <div class="field col-2">
            <label for="dolarvalue">CIF</label>
                <InputText
                  v-model="this.configuraciones.ciif"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              </div>    
          </div>
          </div>    
                  <div class="">
          <div class="">
            <div class="formgrid grid">
              <div class="field col-2">
            <label for="dolarvalue">Materia Prima Indirecta</label>
                <InputText
                  v-model="this.configuraciones.mpi"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              </div>    
          </div>
          </div>   
                  <div class="">
          <div class="">
            <div class="formgrid grid">
              <div class="field col-2">
            <label for="dolarvalue">Mano de Obra Indirecta</label>
                <InputText
                  v-model="this.configuraciones.moi"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              </div>    
          </div>
          </div>   
        <div class="">
          <div class="">
            <div class="formgrid grid">
              <div class="field col-2">
            <label for="dolarvalue">Margen</label>
                <InputText
                  v-model="this.configuraciones.margen"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
              </div>    
          </div>
          </div>   

          <Button label="Guardar" class="btn btn-primary" @click="saveConfiguraciones()" />
         
    </div>
    <div>
        
    </div>
</template>
<script>
    import CrudService from "./../services/crud.service";

    export default {
        name: "Configuraciones",
        data() {
            return {
                showModal: false,
                form: {
                    nombre: "",
                    valor: ""
                },
                configuraciones: []
            };
        },
        methods: {
            getConfiguraciones() {
                CrudService.getCRUD("configuraciones").then(response => {
                    this.configuraciones = response;
                });
            },  
            saveConfiguraciones() {
                CrudService.updateCRUD("configuraciones","configuraciones", this.configuraciones).then(response => {
                    this.getConfiguraciones(); 
                    this.$toast.add({
                      severity: "success",
                      summary: "Éxito!",
                      detail: "Actualizado correctamente.",
                      life: 3000,
                    });
                });
            }
        },
        mounted() {
            this.getConfiguraciones();
        }
    };
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
